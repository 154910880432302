import React, { useEffect, useState } from "react"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const Chapter4 = props => {
  const [textVisible, showText] = useState(false)
  const [titleVisible, showTitle] = useState(false)
  const [switchBtn, switchButtons] = useState(false)
  const [started, startFlow] = useState(false)
  const [blurredAnimation, setBlur] = useState("")
  const [btnsVisible, showButtons] = useState("")
  const [audio, setAudio] = useState(1)

  const [background1, showBackground1] = useState(false)
  const [background2, showBackground2] = useState(false)
  const [background3, showBackground3] = useState(false)
  const [background4, showBackground4] = useState(false)
  const [background5, showBackground5] = useState(false)

  const [leaf1, showLeaf1] = useState(false)
  const [leaf2, showLeaf2] = useState(false)
  const [leaf3, showLeaf3] = useState(false)

  const [text, setText] = useState("Sound and movement is all around us.")

  useEffect(() => {
    console.log("now on chapter 4")

    startFlow(true)

    setTimeout(function () {
      showLeaf1(true)
      showLeaf2(true)
      showLeaf3(true)
    }, 1000)

    setTimeout(function () {
      showBackground1(true)
    }, 2000)

    setTimeout(function () {
      showBackground2(true)
    }, 3000)

    setTimeout(function () {
      showBackground3(true)
    }, 4000)

    setTimeout(function () {
      showBackground4(true)
    }, 5000)

    setTimeout(function () {
      showBackground5(true)
      showText(true)
    }, 6000)

    setTimeout(function () {
      showText(false)
      props.backgroundSound.current.play()
      props.backgroundSound.current.fade(0.0, 1.0, 15000)
      console.log("fading in mask sound for chapter 4")
    }, 10000)

    setTimeout(function () {
      setText("Listen to the trees sway.")
      showText(true)
    }, 12000)

    setTimeout(function () {
      props.pauseIntro()
      showText(false)
    }, 16000)

    setTimeout(function () {
      showTitle(true)
    }, 18000)

    setTimeout(function () {
      showTitle(false)
    }, 22000)

    setTimeout(function () {
      setText("I think I can hear a bird singing.")
      showText(true)
      props.birdSound1.current.play()
      props.birdSound1.current.fade(0.0, 1.0, 1000)
      console.log("fading in bird sound 1 for chapter 4")
    }, 24000)

    setTimeout(function () {
      showText(false)
    }, 28000)

    setTimeout(function () {
      setText(" Listen closely, can you hear its song?")
      showText(true)
    }, 30000)

    setTimeout(function () {
      setBlur("focus")
    }, 32000)

    setTimeout(function () {
      showButtons(true)
    }, 34000)
  }, [props])

  // can hear audio
  const canHear = () => {
    showButtons(false)
    showText(false)
    cookies.set("chapter4", audio, { path: "/" })
    props.SendToGoogle()
    console.log("yes can hear")

    setTimeout(function () {
      switchButtons(true)
      switch (audio) {
        case 1:
          props.birdSound1.current.fade(1.0, 0.0, 1000)
          break
        case 2:
          props.birdSound2.current.fade(1.0, 0.0, 1000)
          break
        case 3:
          props.birdSound3.current.fade(1.0, 0.0, 1000)
          break
        case 5:
          props.birdSound5.current.fade(1.0, 0.0, 1000)
          break
        default:
          console.log("default")
          break
      }

      if (audio !== 4) {
        // play bird 4 (0 db)
        props.birdSound4.current.play()
        props.birdSound4.current.fade(0.0, 1.0, 1000)
      }
      props.backgroundSound.current.fade(1.0, 0.25, 3000)
      console.log(
        "fading in bird sound 4 (0db) for chapter 4 and fading out Mask sound to 25%"
      )
    }, 1000)

    setTimeout(function () {
      setText("That’s a blackbird!")
      showText(true)
    }, 2000)

    setTimeout(function () {
      showText(false)
    }, 6000)

    setTimeout(function () {
      setText("Let’s enjoy the music.")
      showText(true)
    }, 8000)

    setTimeout(function () {
      showText(false)
      props.birdSound4.current.fade(1.0, 0.0, 3000)
      props.backgroundSound.current.fade(0.25, 0.0, 3000)
      console.log("fading out bird and background sound to 0 for chapter 4")
      props.playIntro()
    }, 12000)

    setTimeout(function () {
      setText("I think his song is coming to an end. Shall we move on?")
      showText(true)
    }, 14000)

    setTimeout(function () {
      showButtons(true)
      props.backgroundSound.current.stop()
    }, 15000)
  }

  // cannot hear audio
  const cannotHear = () => {
    showButtons(false)
    showText(false)

    if (audio === 5) {
      cookies.set("chapter4", audio, { path: "/" })
      setTimeout(function () {
        switchButtons(true)
        setText("Perhaps it’s flown off")
        showText(true)
      }, 2000)

      setTimeout(function () {
        showText(false)
        props.birdSound5.current.fade(1.0, 0.0, 3000)
        props.backgroundSound.current.fade(1, 0.0, 3000)
        props.playIntro()
      }, 5000)

      setTimeout(function () {
        setText("When you’re ready, let’s keep walking . . .")
        showText(true)
      }, 7000)

      setTimeout(function () {
        showButtons(true)
      }, 8000)
    } else {
      setTimeout(function () {
        setText("Ok let’s move a bit closer and listen again.")
        showText(true)
      }, 2000)

      setTimeout(function () {
        showText(false)
      }, 5000)

      setTimeout(function () {
        setText("Listen closely, can you hear it?")
        showText(true)
      }, 7000)

      setTimeout(function () {
        showButtons(true)
      }, 8000)
    }

    switch (audio) {
      case 1:
        setAudio(2)
        props.birdSound1.current.fade(1.0, 0.0, 3000)
        props.birdSound2.current.play()
        props.birdSound2.current.fade(0.0, 1.0, 3000)
        console.log("trying 2nd bird call")
        break
      case 2:
        setAudio(3)
        props.birdSound2.current.fade(1.0, 0.0, 3000)
        props.birdSound3.current.play()
        props.birdSound3.current.fade(0.0, 1.0, 3000)
        console.log("trying 3rd bird call")
        break
      case 3:
        setAudio(4)
        props.birdSound3.current.fade(1.0, 0.0, 3000)
        props.birdSound4.current.play()
        props.birdSound4.current.fade(0.0, 1.0, 3000)
        console.log("trying 4th bird call")
        break
      case 4:
        setAudio(5)
        props.birdSound4.current.fade(1.0, 0.0, 3000)
        props.birdSound5.current.play()
        props.birdSound5.current.fade(0.0, 1.0, 3000)
        console.log("trying 5th bird call")
        break
      case 5:
        console.log("user still cannot hear bird, moving on. ")
        props.SendToGoogle()
        break
      default:
        console.log("default")
        break
    }
  }

  const continueChapter = () => {
    startFlow(false)
    showButtons(false)
    showText(false)

    setTimeout(function () {
      props.nextChapter()
    }, 3000)
  }

  return (
    <>
      <div
        className={
          "screen screen-experience chapter chapter4 " +
          (started ? "active" : "inactive")
        }
      >
        <div className="screen_inner">
          <div
            className={background1 ? "background-1 in-view" : "background-1"}
          ></div>
          <div
            className={background2 ? "background-2 in-view" : "background-2"}
          ></div>
          <div
            className={background3 ? "background-3 in-view" : "background-3"}
          ></div>
          <div
            className={background4 ? "background-4 in-view" : "background-4"}
          ></div>
          <div
            className={background5 ? "background-5 in-view" : "background-5"}
          ></div>
          <div className={leaf1 ? "leaf-1 in-view" : "leaf-1"}></div>
          <div className={leaf2 ? "leaf-2 in-view" : "leaf-2"}></div>
          <div className={leaf3 ? "leaf-3 in-view" : "leaf-3"}></div>
          <div className={"blurred " + blurredAnimation}></div>
          <div className="overlay"></div>
          <div className={"title " + (titleVisible ? "visible" : "")}>
            <h4>chapter four</h4>
            <h2>The Wind</h2>
          </div>
          <div className={"text_container "}>
            <p className={"text " + (textVisible ? "visible" : "")}>{text}</p>
            <div className={"buttons " + (btnsVisible ? "visible" : "")}>
              {switchBtn ? (
                <button
                  className="button white"
                  onClick={() => continueChapter()}
                >
                  Continue
                </button>
              ) : (
                <>
                  <button
                    className="toggle-button"
                    onClick={() => cannotHear()}
                  >
                    Not yet.
                  </button>
                  <button className="toggle-button" onClick={() => canHear()}>
                    I hear it!
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="chapter-marker">
            <span></span>
            <span></span>
            <span></span>
            <span className="active"></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Chapter4
