import React, { useEffect, useRef, useState } from "react"
import { Howl } from "howler"
import ambient from "../audio/intro-musical.mp3"

const Intro = props => {
  const ambientSound = useRef()
  const [started, startFlow] = useState(false)
  const [text, setText] = useState("Hello")
  const [textVisible, showText] = useState(false)
  const [buttonVisible, showButton] = useState(false)
  const [clouds, hideClouds] = useState(false)
  const [bgAnimation, setBgAnimation] = useState("")

  useEffect(() => {
    ambientSound.current = new Howl({
      src: [ambient],
      volume: 0.0,
      loop: false,
    })
  }, [])

  const startExperience = () => {
    startFlow(true)
    props.menuSound()
    ambientSound.current.play()
    ambientSound.current.fade(0.0, 1.0, 5000)
    console.log("fading in ambient sound for intro")

    // show text after 4 seconds
    setTimeout(function () {
      showText(true)
    }, 4000)

    // hide text after 4 seconds
    setTimeout(function () {
      showText(false)
    }, 8000)

    // show next text after 8 seconds
    setTimeout(function () {
      setText("The woods are alive with birdsong.")
      showText(true)
    }, 10000)

    // hide text after 4 seconds
    setTimeout(function () {
      showText(false)
    }, 14000)

    // next text
    setTimeout(function () {
      setText("Shall we go for a walk?")
      showText(true)
    }, 16000)

    // hide text after 4 seconds
    setTimeout(function () {
      showText(false)
    }, 20000)

    // next text
    setTimeout(function () {
      setText("We can let our ears tell us about our surroundings.")
      showText(true)
    }, 22000)

    // hide text after 4 seconds
    setTimeout(function () {
      showText(false)
    }, 26000)

    setTimeout(function () {
      setText("It’s important we tread carefully")
      showText(true)
    }, 28000)

    setTimeout(function () {
      showText(false)
    }, 32000)

    setTimeout(function () {
      setText("to avoid disturbing the wildlife.")
      showText(true)
    }, 34000)

    // hide text again
    setTimeout(function () {
      showText(false)
    }, 38000)

    setTimeout(function () {
      setText("When you’re ready, let’s go.")
      showText(true)
    }, 40000)

    setTimeout(function () {
      showButton(true)
    }, 42000)
  }

  const moveForward = () => {
    ambientSound.current.fade(1.0, 0.0, 5000)
    console.log("fading out ambient sound for intro")

    setBgAnimation("hide")
    showText(false)
    showButton(false)
    hideClouds(true)

    setTimeout(function () {
      props.playIntro()
    }, 4000)

    setTimeout(function () {
      ambientSound.current.stop()
      props.nextChapter()
    }, 5000)
  }

  return (
    <div className={"screen screen-intro " + (started ? "animate" : "")}>
      <div className={"intro-background " + bgAnimation}></div>
      <div className={"clouds " + (clouds ? "hide" : "")}></div>
      <div className="bird bird-1"></div>
      <div className="bird bird-2"></div>
      <div className="bird bird-3"></div>
      <div className="bird bird-4"></div>
      <div className="bird bird-5"></div>
      <div className="overlay"></div>
      <div className="opening-text">
        <h2>
          Hearing <br /> Birdsong
        </h2>
        <p>Explore your hearing through the sounds of nature</p>
        <button className="button white" onClick={startExperience}>
          Let’s Begin
        </button>
      </div>

      <div className="text_container">
        <p className={"text " + (textVisible ? "visible" : "")}>{text}</p>
        <div className={"buttons " + (buttonVisible ? "visible" : "")}>
          <button className="button white" onClick={moveForward}>
            Head in to the woods
          </button>
        </div>
      </div>
    </div>
  )
}

export default Intro
