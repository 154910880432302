import React, { useEffect, useState } from "react"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const Finish = () => {
  const [results, setResults] = useState([])
  useEffect(() => {
    let cookieResults = []

    cookieResults.push(
      { chapter: "Chapter 1: The Stream", result: cookies.get("chapter1") },
      { chapter: "Chapter 2: The Rain", result: cookies.get("chapter2") },
      { chapter: "Chapter 3: The Bird hide", result: cookies.get("chapter3") },
      { chapter: "Chapter 4: The Wind", result: cookies.get("chapter4") },
      { chapter: "Chapter 5: The Campfire", result: cookies.get("chapter5") }
    )

    setResults(cookieResults)

    console.log("chapter 1 ", cookies.get("chapter1"))
    console.log("chapter 2 ", cookies.get("chapter2"))
    console.log("chapter 3 ", cookies.get("chapter3"))
    console.log("chapter 4 ", cookies.get("chapter4"))
    console.log("chapter 5 ", cookies.get("chapter5"))
  }, [])

  return (
    <>
      <div className="birdsong-screen results">
        <div className="results_inner">
          <h5>Results</h5>
          <h3>After our walk together, it looks like your hearing is:</h3>
          <h2>Good</h2>
          <h3>
            Based on this score we would recommend that no immediate action is
            necessary.
          </h3>
          <p>
            {" "}
            Please be aware that this is not designed to replace a professional
            hearing test.{" "}
          </p>
          <ul className="results-list">
            {results.map((result, i) => {
              return (
                <li>
                  <span>{result.chapter}</span>{" "}
                  <span className="result_number">{result.result}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Finish
