import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Howl } from "howler"
import Cookies from "universal-cookie"
import headphones from "../images/headphones.svg"
import volume from "../images/volume.svg"
import quiet from "../images/quiet.svg"
import Div100vh from "react-div-100vh"
import menu from "../audio/menu-calm.mp3"
import Intro from "../chapters/intro"
import Chapter1 from "../chapters/chapter1"
import Chapter2 from "../chapters/chapter2"
import Chapter3 from "../chapters/chapter3"
import Chapter4 from "../chapters/chapter4"
import Chapter5 from "../chapters/chapter5"
import Finish from "../chapters/finish"
import intro1 from "../audio/chapter1/intro.mp3"
import intro2 from "../audio/chapter2/intro.mp3"
import intro3 from "../audio/chapter3/intro.mp3"
import intro4 from "../audio/chapter4/intro_new.mp3"
import intro5 from "../audio/chapter5/intro_new.mp3"

// chapter 1
import chapter1_background from "../audio/chapter1/background_loop.mp3"
import chapter1_bird1 from "../audio/chapter1/bird1.mp3"
import chapter1_bird2 from "../audio/chapter1/bird2.mp3"
import chapter1_bird3 from "../audio/chapter1/bird3.mp3"
import chapter1_bird4 from "../audio/chapter1/bird4.mp3"
import chapter1_bird5 from "../audio/chapter1/bird5.mp3"

// chapter 2
import chapter2_background from "../audio/chapter2/background_loop.mp3"
import chapter2_bird1 from "../audio/chapter2/bird1.mp3"
import chapter2_bird2 from "../audio/chapter2/bird2.mp3"
import chapter2_bird3 from "../audio/chapter2/bird3.mp3"
import chapter2_bird4 from "../audio/chapter2/bird4.mp3"
import chapter2_bird5 from "../audio/chapter2/bird5.mp3"

// chapter 3
import chapter3_background from "../audio/chapter3/mask-loop-3-v4.mp3"
import chapter3_bird1 from "../audio/chapter3/bird1.mp3"
import chapter3_bird2 from "../audio/chapter3/bird2.mp3"
import chapter3_bird3 from "../audio/chapter3/bird3.mp3"
import chapter3_bird4 from "../audio/chapter3/bird4.mp3"
import chapter3_bird5 from "../audio/chapter3/bird5.mp3"

// chapter 4
import chapter4_background from "../audio/chapter4/mask-loop-4-new.mp3"
import chapter4_bird1 from "../audio/chapter4/bird1.mp3"
import chapter4_bird2 from "../audio/chapter4/bird2.mp3"
import chapter4_bird3 from "../audio/chapter4/bird3.mp3"
import chapter4_bird4 from "../audio/chapter4/bird4.mp3"
import chapter4_bird5 from "../audio/chapter4/bird5.mp3"

// chapter 5
import chapter5_background from "../audio/chapter5/mask-loop-5-v9.mp3"
import chapter5_bird1 from "../audio/chapter5/bird1.mp3"
import chapter5_bird2 from "../audio/chapter5/bird2.mp3"
import chapter5_bird3 from "../audio/chapter5/bird3.mp3"
import chapter5_bird4 from "../audio/chapter5/bird4.mp3"
import chapter5_bird5 from "../audio/chapter5/bird5.mp3"
import chapter5_outro from "../audio/chapter5/outro.mp3"

const cookies = new Cookies()

const IndexPage = () => {
  const [chapter, setChapter] = useState(0)
  const menuSound = useRef()
  const introSound1 = useRef()
  const introSound2 = useRef()
  const introSound3 = useRef()
  const introSound4 = useRef()
  const introSound5 = useRef()
  const [popup, showPopup] = useState(true)
  const [status, setStatus] = useState(0)

  // chapter 1
  const chapter1_backgroundSound = useRef()
  const chapter1_birdSound1 = useRef()
  const chapter1_birdSound2 = useRef()
  const chapter1_birdSound3 = useRef()
  const chapter1_birdSound4 = useRef()
  const chapter1_birdSound5 = useRef()

  // chapter 2
  const chapter2_backgroundSound = useRef()
  const chapter2_birdSound1 = useRef()
  const chapter2_birdSound2 = useRef()
  const chapter2_birdSound3 = useRef()
  const chapter2_birdSound4 = useRef()
  const chapter2_birdSound5 = useRef()

  // chapter 3
  const chapter3_backgroundSound = useRef()
  const chapter3_birdSound1 = useRef()
  const chapter3_birdSound2 = useRef()
  const chapter3_birdSound3 = useRef()
  const chapter3_birdSound4 = useRef()
  const chapter3_birdSound5 = useRef()

  // chapter 4
  const chapter4_backgroundSound = useRef()
  const chapter4_birdSound1 = useRef()
  const chapter4_birdSound2 = useRef()
  const chapter4_birdSound3 = useRef()
  const chapter4_birdSound4 = useRef()
  const chapter4_birdSound5 = useRef()

  // chapter 5
  const chapter5_backgroundSound = useRef()
  const chapter5_birdSound1 = useRef()
  const chapter5_birdSound2 = useRef()
  const chapter5_birdSound3 = useRef()
  const chapter5_birdSound4 = useRef()
  const chapter5_birdSound5 = useRef()
  const chapter5_outroSound = useRef()

  useEffect(() => {
    menuSound.current = new Howl({
      src: [menu],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("menu sound loaded")
      },
      onplay: function () {
        console.log("menu sound is playing")
      },
      onstop: function () {
        console.log("stopped menu bird sound (initial)")
      },
    })
    introSound1.current = new Howl({
      src: [intro1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Intro 1 loaded")
      },
      onstop: function () {
        console.log("stopped intro chapter 1")
      },
    })
    introSound2.current = new Howl({
      src: [intro2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Intro 2 loaded")
      },
      onstop: function () {
        console.log("stopped intro chapter 2")
      },
    })
    introSound3.current = new Howl({
      src: [intro3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Intro 3 loaded")
      },
      onstop: function () {
        console.log("stopped intro chapter 3")
      },
    })
    introSound4.current = new Howl({
      src: [intro4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Intro 4 loaded")
      },
      onstop: function () {
        console.log("stopped intro chapter 4")
      },
    })
    introSound5.current = new Howl({
      src: [intro5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Intro 5 loaded")
      },
      onstop: function () {
        console.log("stopped intro chapter 5")
      },
    })

    // load chapter 1 music
    chapter1_backgroundSound.current = new Howl({
      src: [chapter1_background],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("background 1 loaded")
      },
      onloaderror: function () {
        console.log("background 1 error to load")
      },
      onplayerror: function () {
        console.log("background 1 failed to play")
      },
      onplay: function () {
        console.log("background 1 is playing")
      },
      onstop: function () {
        console.log("background 1 has stopped")
      },
    })

    chapter1_birdSound1.current = new Howl({
      src: [chapter1_bird1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 1 Bird 1 loaded")
      },
    })

    chapter1_birdSound2.current = new Howl({
      src: [chapter1_bird2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 1 Bird 2 loaded")
      },
    })

    chapter1_birdSound3.current = new Howl({
      src: [chapter1_bird3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 1 Bird 3 loaded")
      },
    })

    chapter1_birdSound4.current = new Howl({
      src: [chapter1_bird4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 1 Bird 4 loaded")
      },
    })

    chapter1_birdSound5.current = new Howl({
      src: [chapter1_bird5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 1 Bird 5 loaded")
      },
    })

    // load chapter 2 music
    chapter2_backgroundSound.current = new Howl({
      src: [chapter2_background],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("background 2 loaded")
      },
      onloaderror: function () {
        console.log("background 2 error to load")
      },
      onplayerror: function () {
        console.log("background 2 failed to play")
      },
      onplay: function () {
        console.log("background 2 is playing")
      },
      onstop: function () {
        console.log("background 2 has stopped")
      },
    })

    chapter2_birdSound1.current = new Howl({
      src: [chapter2_bird1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 2 Bird 1 loaded")
      },
    })

    chapter2_birdSound2.current = new Howl({
      src: [chapter2_bird2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 2 Bird 2 loaded")
      },
    })

    chapter2_birdSound3.current = new Howl({
      src: [chapter2_bird3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 2 Bird 3 loaded")
      },
    })

    chapter2_birdSound4.current = new Howl({
      src: [chapter2_bird4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 2 Bird 4 loaded")
      },
    })

    chapter2_birdSound5.current = new Howl({
      src: [chapter2_bird5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 2 Bird 5 loaded")
      },
    })

    // load chapter 3 music
    chapter3_backgroundSound.current = new Howl({
      src: [chapter3_background],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("background 3 loaded")
      },
      onloaderror: function () {
        console.log("background 3 error to load")
      },
      onplayerror: function () {
        console.log("background 3 failed to play")
      },
      onplay: function () {
        console.log("background 3 is playing")
      },
      onstop: function () {
        console.log("background 3 has stopped")
      },
    })

    chapter3_birdSound1.current = new Howl({
      src: [chapter3_bird1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 3 Bird 1 loaded")
      },
    })

    chapter3_birdSound2.current = new Howl({
      src: [chapter3_bird2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 3 Bird 2 loaded")
      },
    })

    chapter3_birdSound3.current = new Howl({
      src: [chapter3_bird3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 3 Bird 3 loaded")
      },
    })

    chapter3_birdSound4.current = new Howl({
      src: [chapter3_bird4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 3 Bird 4 loaded")
      },
    })

    chapter3_birdSound5.current = new Howl({
      src: [chapter3_bird5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 3 Bird 5 loaded")
      },
    })

    // load chapter 4 music
    chapter4_backgroundSound.current = new Howl({
      src: [chapter4_background],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("background 4 loaded")
      },
      onloaderror: function () {
        console.log("background 4 error to load")
      },
      onplayerror: function () {
        console.log("background 4 failed to play")
      },
      onplay: function () {
        console.log("background 4 is playing")
      },
      onstop: function () {
        console.log("background 4 has stopped")
      },
    })

    chapter4_birdSound1.current = new Howl({
      src: [chapter4_bird1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 4 Bird 1 loaded")
      },
    })

    chapter4_birdSound2.current = new Howl({
      src: [chapter4_bird2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 4 Bird 2 loaded")
      },
    })

    chapter4_birdSound3.current = new Howl({
      src: [chapter4_bird3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 4 Bird 3 loaded")
      },
    })

    chapter4_birdSound4.current = new Howl({
      src: [chapter4_bird4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 4 Bird 4 loaded")
      },
    })

    chapter4_birdSound5.current = new Howl({
      src: [chapter4_bird5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 4 Bird 5 loaded")
      },
    })

    // load chapter 5 music
    chapter5_backgroundSound.current = new Howl({
      src: [chapter5_background],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("background 5 loaded")
      },
      onloaderror: function () {
        console.log("background 5 error to load")
      },
      onplayerror: function () {
        console.log("background 5 failed to play")
      },
      onplay: function () {
        console.log("background 5 is playing")
      },
      onstop: function () {
        console.log("background 5 has stopped")
      },
    })

    chapter5_birdSound1.current = new Howl({
      src: [chapter5_bird1],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 Bird 1 loaded")
      },
    })

    chapter5_birdSound2.current = new Howl({
      src: [chapter5_bird2],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 Bird 2 loaded")
      },
    })

    chapter5_birdSound3.current = new Howl({
      src: [chapter5_bird3],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 Bird 3 loaded")
      },
    })

    chapter5_birdSound4.current = new Howl({
      src: [chapter5_bird4],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 Bird 4 loaded")
      },
    })

    chapter5_birdSound5.current = new Howl({
      src: [chapter5_bird5],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 Bird 5 loaded")
      },
    })

    chapter5_outroSound.current = new Howl({
      src: [chapter5_outro],
      volume: 0.0,
      loop: true,
      onload: function () {
        console.log("Chapter 5 outro loaded")
      },
    })
  }, [])

  const hidePopup = () => {
    console.log("clicked")
    menuSound.current.play()
    menuSound.current.fade(0.0, 1.0, 5000)
    console.log("fading in menu bird sound (initial)")
    showPopup(false)
  }

  const pauseMenuSound = () => {
    menuSound.current.fade(1.0, 0.0, 5000)
    console.log("fading out menu bird sound (initial)")

    setTimeout(function () {
      menuSound.current.stop()
    }, 5100)
  }

  const playBackgroundSound = chapter => {
    switch (chapter) {
      case 1:
        introSound1.current.play()
        introSound1.current.fade(0.0, 1.0, 3000)
        console.log("fading in intro chapter 1")
        break
      case 2:
        introSound2.current.play()
        introSound2.current.fade(0.0, 1.0, 3000)
        console.log("fading in intro chapter 2")
        break
      case 3:
        introSound3.current.play()
        introSound3.current.fade(0.0, 1.0, 3000)
        console.log("fading in intro chapter 3")
        break
      case 4:
        introSound4.current.play()
        introSound4.current.fade(0.0, 1.0, 3000)
        console.log("fading in intro chapter 4")
        break
      case 5:
        introSound5.current.play()
        introSound5.current.fade(0.0, 1.0, 3000)
        console.log("fading in intro chapter 5")
        break
      default:
        console.log("test")
        break
    }
  }

  const pauseBackgroundSound = chapter => {
    switch (chapter) {
      case 1:
        introSound1.current.fade(1.0, 0.0, 3000)
        console.log("fading out intro chapter 1")

        setTimeout(function () {
          introSound1.current.stop()
        }, 3100)

        break
      case 2:
        introSound2.current.fade(1.0, 0.0, 3000)
        console.log("fading out intro chapter 2")

        setTimeout(function () {
          introSound2.current.stop()
        }, 3100)
        break
      case 3:
        introSound3.current.fade(1.0, 0.0, 3000)
        console.log("fading out intro chapter 3")

        setTimeout(function () {
          introSound3.current.stop()
        }, 3100)
        break
      case 4:
        introSound4.current.fade(1.0, 0.0, 3000)
        console.log("fading out intro chapter 4")

        setTimeout(function () {
          introSound4.current.stop()
        }, 3100)
        break
      case 5:
        introSound5.current.fade(1.0, 0.0, 3000)
        console.log("fading out intro chapter 5")

        setTimeout(function () {
          introSound5.current.stop()
        }, 3100)
        break
      default:
        console.log("pause test")
        break
    }
  }

  const SendToGoogle = chapter => {
    let sound = ""

    switch (chapter) {
      case 1:
        sound = parseInt(cookies.get("chapter1"))
        break
      case 2:
        sound = parseInt(cookies.get("chapter2"))
        break
      case 3:
        sound = parseInt(cookies.get("chapter3"))
        break
      case 4:
        sound = parseInt(cookies.get("chapter4"))
        break
      case 5:
        sound = parseInt(cookies.get("chapter5"))
        break
      default:
        console.log("google test")
        break
    }

    console.log("google", "chapter " + chapter, "bird " + sound)

    typeof window !== "undefined" &&
      window.gtag("event", "user_can_hear", {
        event_category: "chapter",
        event_label: "chapter " + chapter,
        value: "chapter " + chapter + " bird " + sound,
      })
  }

  return (
    <Div100vh>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Birdsong</title>
        <link rel="canonical" href="https://beta.hearingbirdsong.com/" />
      </Helmet>
      <div className="birdsong">
        <div className="birdsong-screen">
          <>
            {(() => {
              switch (chapter) {
                case 1:
                  return (
                    <Chapter1
                      nextChapter={() => setChapter(2)}
                      playIntro={() => playBackgroundSound(2)}
                      pauseIntro={() => pauseBackgroundSound(1)}
                      backgroundSound={chapter1_backgroundSound}
                      birdSound1={chapter1_birdSound1}
                      birdSound2={chapter1_birdSound2}
                      birdSound3={chapter1_birdSound3}
                      birdSound4={chapter1_birdSound4}
                      birdSound5={chapter1_birdSound5}
                      SendToGoogle={() => SendToGoogle(1)}
                    />
                  )
                case 2:
                  return (
                    <Chapter2
                      nextChapter={() => setChapter(3)}
                      playIntro={() => playBackgroundSound(3)}
                      pauseIntro={() => pauseBackgroundSound(2)}
                      backgroundSound={chapter2_backgroundSound}
                      birdSound1={chapter2_birdSound1}
                      birdSound2={chapter2_birdSound2}
                      birdSound3={chapter2_birdSound3}
                      birdSound4={chapter2_birdSound4}
                      birdSound5={chapter2_birdSound5}
                      SendToGoogle={() => SendToGoogle(2)}
                    />
                  )
                case 3:
                  return (
                    <Chapter3
                      nextChapter={() => setChapter(4)}
                      playIntro={() => playBackgroundSound(4)}
                      pauseIntro={() => pauseBackgroundSound(3)}
                      backgroundSound={chapter3_backgroundSound}
                      birdSound1={chapter3_birdSound1}
                      birdSound2={chapter3_birdSound2}
                      birdSound3={chapter3_birdSound3}
                      birdSound4={chapter3_birdSound4}
                      birdSound5={chapter3_birdSound5}
                      SendToGoogle={() => SendToGoogle(3)}
                    />
                  )
                case 4:
                  return (
                    <Chapter4
                      nextChapter={() => setChapter(5)}
                      playIntro={() => playBackgroundSound(5)}
                      pauseIntro={() => pauseBackgroundSound(4)}
                      backgroundSound={chapter4_backgroundSound}
                      birdSound1={chapter4_birdSound1}
                      birdSound2={chapter4_birdSound2}
                      birdSound3={chapter4_birdSound3}
                      birdSound4={chapter4_birdSound4}
                      birdSound5={chapter4_birdSound5}
                      SendToGoogle={() => SendToGoogle(4)}
                    />
                  )
                case 5:
                  return (
                    <Chapter5
                      nextChapter={() => setChapter(6)}
                      pauseIntro={() => pauseBackgroundSound(5)}
                      backgroundSound={chapter5_backgroundSound}
                      birdSound1={chapter5_birdSound1}
                      birdSound2={chapter5_birdSound2}
                      birdSound3={chapter5_birdSound3}
                      birdSound4={chapter5_birdSound4}
                      birdSound5={chapter5_birdSound5}
                      outro={chapter5_outroSound}
                      SendToGoogle={() => SendToGoogle(5)}
                    />
                  )
                case 6:
                  return <Finish />
                default:
                  return (
                    <>
                      {popup && (
                        <div className="popup">
                          <div className="popup_inner">
                            <h4>Hearing Birdsong Beta</h4>

                            {status === 0 && (
                              <div>
                                <p>
                                  Thank you for taking part in the beta testing
                                  of our prototype. It will run through an
                                  introduction and five chapters, with your
                                  results appearing at the end. It should only
                                  take a few minutes to complete. Through this
                                  project we’re hoping to shine a light on the
                                  potential to humanise clinical processes.
                                </p>
                                <p>
                                  <strong>
                                    Please be aware that at this time, this is
                                    not designed to replace a professional
                                    hearing test. Any results or recommendations
                                    are for demonstration purposes.
                                  </strong>
                                </p>
                                <button
                                  className="button black"
                                  onClick={() => setStatus(1)}
                                >
                                  Next
                                </button>
                                <a
                                  href="https://beta.hearingbirdsong.com/privacy-policy"
                                  className="privacy-link"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Privacy policy
                                </a>
                              </div>
                            )}

                            {status === 1 && (
                              <div>
                                <p>
                                  To get the most out of the experience, please:
                                </p>
                                <div className="popup_image">
                                  <img src={headphones} alt="headphones icon" />
                                  <p>Wear headphones</p>
                                </div>

                                <button
                                  className="button black"
                                  onClick={() => setStatus(2)}
                                >
                                  Next
                                </button>
                              </div>
                            )}

                            {status === 2 && (
                              <div>
                                <p>
                                  To get the most out of the experience, please:
                                </p>
                                <div className="popup_image">
                                  <img src={volume} alt="volume icon" />
                                  <p>
                                    Set your device volume to a comfortable
                                    level
                                  </p>
                                </div>

                                <button
                                  className="button black"
                                  onClick={() => setStatus(3)}
                                >
                                  Next
                                </button>
                              </div>
                            )}

                            {status === 3 && (
                              <div>
                                <p>
                                  To get the most out of the experience, please:
                                </p>
                                <div className="popup_image">
                                  <img src={quiet} alt="quiet icon" />
                                  <p>Ensure you’re in a quiet environment</p>
                                </div>

                                <button
                                  className="button black"
                                  onClick={hidePopup}
                                >
                                  Next
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <Intro
                        menuSound={pauseMenuSound}
                        playIntro={() => playBackgroundSound(1)}
                        nextChapter={() => setChapter(1)}
                      />
                    </>
                  )
              }
            })()}
          </>
        </div>
      </div>
    </Div100vh>
  )
}

export default IndexPage
